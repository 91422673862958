.btn-primary {
    color: #ffffff !important;
    border-color: #25a9df !important;
    background-color: #25a9df !important;
}

.btn-secondary {
    color:  #25a9df!important;
    border-color: #25a9df !important;
    background-color: #ffffff !important;
}