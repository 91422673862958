.LIGHT{
    .MuiCheckbox-root {
    color:#bebebf !important;
    }
    .MuiCheckbox-root.Mui-checked{
    color: #2596be !important;
    }
}

.DARK{
    .MuiCheckbox-root{
    color:#ffffff !important;
    }
    .MuiCheckbox-root.Mui-checked{
    color: #2596be !important;
    }  
}
