@import "variables";

///////////////////////////////
// Spacing classes
///////////////////////////////

$attributes: (
        "m": "margin",
        "p": "padding"
);

$spacing_break_points: (
        "xs": (0px 0.6),
        "sm": (600px 0.7),
        "md": (900px 0.8),
        "lg": (1200px 1)
);

@each $attribute_key, $attribute_value in $attributes {
  @each $size_key, $size_value in $spacing {
    @each $side_key, $side_values in $sides {
      @each $break_point_key, $break_point_value in $spacing_break_points {
        @media (min-width: nth($break_point_value, 1)) {
          @each $side_value in $side_values {
            //for positive values
            .#{$attribute_key}#{$side_key}-#{$size_key} {
              #{$attribute_value}-#{$side_value}: $size_value * nth($break_point_value, 2) !important;
            }
            //for negative values
            .#{$attribute_key}#{$side_key}-#{$size_key}-n {
              #{$attribute_value}-#{$side_value}: -$size_value * nth($break_point_value, 2) !important;
            }
          }
        }
      }
    }
  }
}

///////////////////////////////
// Gap
///////////////////////////////

@each $gap_key, $gap_value in $gap_values {
  .gap-#{$gap_key} {
      gap: $gap_value !important;
  }
}

///////////////////////////////
// Alignment classes
///////////////////////////////

/** Dynamic Width and Height classes start**/

// units defined
$units: px, rem;
$units-viewport: vh, vw;

@mixin dynamic-units($property, $start, $end, $step, $unit) {
    @for $i from $start through $end {
        .#{$property}-#{$i * $step}-#{$unit} {
            #{$property}: #{$i * $step}#{$unit} !important;
        }
    }
}
@mixin dynamic-percentages($property, $start, $end, $step) {
  @for $i from $start through $end {
    .#{$property}-#{$i * $step} {
      #{$property}: #{$i * $step + '%'} !important;
    }
  }
}

@each $property in width, min-width, max-width, height, min-height, max-height {

    @each $unit in $units {
        @include dynamic-units($property, 1, 1000, 1, $unit);  //min-width-4-px
    }

    @each $unit in $units-viewport {
        @include dynamic-units($property, 1, 100, 5, $unit);  //min-width-10-vw / min-height-vh
    } 

    @include dynamic-percentages($property, 1, 100, 5);  //width-100

    @each $display_key, $value in $display_sizes {
      .#{$property}-#{$display_key} { 
        #{$property}: $value !important; //width-auto / width-content
       }
    }
}

/** Dynamic Width and Height classes end**/


///////////////////////////////
// Responsive Fonts
///////////////////////////////

$font_break_points: (
        "xs": (0px 0.7),
        "sm": (600px 0.8),
        "md": (900px 0.9),
        "lg": (1800px 1)
);

@each $break_point_key, $break_point_value in $font_break_points {
  @media (min-width: nth($break_point_value, 1)) {
    html {
      font-size: 15px * nth($break_point_value, 2);
    }
  }
}

// font weights

@each $weight_key, $font_weight in $font_weights {
  .txt-weight-#{$weight_key} {
      font-weight: $font_weight !important;
  }
}

// font sizes

@each $size_key, $font_size in $font_sizes {
  .txt-size-#{$size_key} {
      font-size: $font_size !important;
  }
}

// font decorations

@each $decoration_key, $decoration_value in $font_decorations {
  .txt-decoration-#{$decoration_key} {
    text-decoration: $decoration_value;
  }
}
// font transform

@each $transform_key, $transform_value in $font_transform {
  .txt-transform-#{$transform_key} {
    text-transform: $transform_value;
  }
}
// list style

@each $list_type_key, $list_type_value in $list_type {
  .list-type-#{$list_type_key} {
    list-style-type: $list_type_value;
  }
}
///////////////////////////////
// Disabled and Opacity Styles
///////////////////////////////

.dimmed {
  opacity: 0.5;
}

.disabled-opacity {
  opacity: 0.8;
}

.disable {
  cursor: not-allowed;
  user-select: none;
  pointer-events: none;
}

///////////////////////////////
// Border Radius
///////////////////////////////

$border_side: (
  "tl": top-left,
  "tr": top-right,
  "bl": bottom-left,
  "br": bottom-right,
);

@each $radius_key, $radius_value in $border_radius_values {
  @each $side_key, $side_values in $border_side {
    $border-radius-class: ".b-rad-#{$side_key}-#{$radius_key}";

    //for specific side
    #{$border-radius-class} {
      @each $side-value in $side_values {
        border-#{$side-value}-radius: $radius_value !important;
      }
    }

    //for full border
    .b-rad-#{$radius_key} {
      border-radius: $radius_value !important;
    }
  }
}

.b-none {
  border: none !important;
}

///////////////////////////////
// Scroll Bar Styles
///////////////////////////////

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
}