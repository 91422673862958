:root{
  .DARK {
      --client-primary:#165B81;
      --client-secondary: #ffffff;

      --loader-spinner-color: var(--client-primary);
      --toggle-bar-bg-color: rgba(22, 91, 129, 0.5);
      --highlight-primary: #165b81;
      --transparent: transparent;

      /* background */
      --background-color-primary: var(--client-primary);
      --background-color-secondary: var(--client-secondary);
      --bg-color-highlight-primary: var(--highlight-primary);
      --background-color-default:  #212121; 
      --background-color-opposite: #efefef; 
      --background-color-1: #000000; 
      --background-color-2: #25a9df;  
      --background-color-3: #2d2d2d; 
      --background-color-4: #2d2d2d;
      --background-color-5: #568091;
      --background-color-6: #c5d8ed;
      --background-color-7: #000000;
      --background-color-8: #1d1d1c;
    

      /*  border */
      --border-color-primary: var(--client-primary);
      --border-color-secondary: var(--client-secondary);
      --border-color-default: #303030dd; /* Default border color / b-${width}-default */
      --border-color-1: #434343; /*Review Form Border Color / b-${width}-shaded-1 */    
      --border-color-2: #d3d3d3; /* b-${width}-shaded-2 */
      --border-color-3: #0b496a; /* b-${width}-shaded-3 */
      --border-color-4: #f70505; /* Input border color / b-${width}-shaded-4  */
      --border-color-5: #ffffff;

      

      /* text */
      --text-color-primary: var(--client-primary);
      --text-color-secondary: var(--client-secondary);
      --text-color-highlight-primary: var(--highlight-primary);
      --text-color-default: #ffffff; /* txt-color-default */
      --text-color-opposite:#1f1f1f; /* txt-color-secondary */
      --text-color-1: #ffffff; /* Title text color / txt-color-heading-1 */
      --text-color-2: #5BB5E8; /* Link text color / txt-color-heading-2 */
      --text-color-3: #0000EE; /* txt-color-body-shaded-1 */
      --text-color-4: #bfbfbf; /* Base primary text color / txt-color-body-shaded-2 */
      --text-color-5 : #76923c; /* Input text color */ 
      --text-color-6 :#ffffff;

      /* primary button */
      --btn-bg-primary: var(--client-primary);
      --btn-text-primary: var(--text-color-secondary);
      --btn-border-primary: var(--border-color-primary);
      --btn-bg-primary-active: color-mix(in srgb, var(--client-primary) 60%,white);
      --btn-bg-primary-hover: color-mix(in srgb, var(--client-primary) 80%, rgb(255, 255, 255));

      /* secondary button */
      --btn-bg-secondary: var(--background-color-1);
      --btn-text-secondary: var( --text-color-secondary);
      --btn-border-secondary: var(--border-color-primary) !important;
      --btn-bg-secondary-active: color-mix(in srgb, var(--client-secondary) 80%, black);
      --btn-bg-secondary-hover: color-mix(in srgb, var(--client-primary) 50%,black);

      /* tertiary button */
      --btn-bg-tertiary: var(--transparent);
      --btn-text-tertiary: var(--text-color-primary);
      --btn-border-tertiary: var(--transparent);
      --btn-bg-tertiary-active: color-mix(in srgb, var(--client-secondary) 60%, white);
      --btn-bg-tertiary-hover: color-mix(in srgb, var(--client-primary) 40%, black);
        
      /* status-btn-success*/
      --btn-bg-success: #249759;
      --btn-text-success: var(--text-color-6);
      --btn-border-success: #2EC071;
      --btn-bg-success-active: #92d9ac;
      --btn-bg-success-hover: #00a54f;

      /* status-btn-warning*/
      --btn-bg-warning: #cabb16;
      --btn-text-warning: var(--text-color-6);
      --btn-border-warning: #c9b905;
      --btn-bg-warning-active: #cfc341;
      --btn-bg-warning-hover: #c9ba0e;

      /* status-btn-warning*/
      --btn-bg-warning-high: #e1931e;
      --btn-text-warning-high: var(--text-color-6);
      --btn-border-warning-high: #e28904;
      --btn-bg-warning-high-active: #e19e39;
      --btn-bg-warning-high-hover: #e38e0e;

      /* status-btn-critical*/
      --btn-bg-critical: #a43209;
      --btn-text-critical: var(--text-color-6);
      --btn-border-critical: #DC3545;
      --btn-bg-critical-active: #fd464e;
      --btn-bg-critical-hover: #c02333;

      /* status-btn-info*/
      --btn-bg-info: #3a98be;
      --btn-text-info: var(--text-color-6);
      --btn-border-info: #32BBF3;
      --btn-bg-info-active: #ade2fa;
      --btn-bg-info-hover: #00a0ef;

      /* disabled button */
      --btn-bg-disabled: var(--background-color-10);
      --btn-text-disabled: var(--text-color-9);
      --btn-border-disabled: var(--border-color-5);

      /* sidebar */
      /* --sidebar-link-hover: var(--background-color-8);
      --sidebar-link-active: var(--background-color-4); */

      /* scrollbar */
      /* --scrollbar-track: green;
      --scrollbar-thumb: red;
      --scrollbar-thumb-hover: blue; */

      /* shadow */
      --shadow-dark: rgba(0, 0, 0, 0.32); /* box-shadow-dark */
      --shadow-light:rgba(144, 203, 255, 0.33); /* box-shadow-light */
      --shadow-info: #225970;
      --shadow-success: #2ec0711a;
      --shadow-danger: #751b1b44;
      --shadow-warning: #dacc304f;    
      --shadow-gray: #b6b5b552;

      --text-danger: #ff3e3e; 
      --text-info: #4da6ff;
      --text-success:#76923c;
      --text-warning: #ff990a;
      --text-caution: #e3cc00;

      /*Status Chip Colors*/
      --default-bg: #373c41;
      --default-txt: var(--text-color-6);
      --default-border: #7e8992;

      --success-bg: #104528;
      --success-txt: var(--text-color-6);
      --success-border: #2EC071;

      --info-bg: #0f455d;
      --info-txt: var(--text-color-6);
      --info-border: #29a6dc;

      --warning-bg: #403b07;
      --warning-txt: var(--text-color-6);
      --warning-border: #cabb16;

      --warning-high-bg: #5a3b0c;
      --warning-high-txt: var(--text-color-6);
      --warning-high-border: #e1931e;

      --danger-bg: #540910;
      --danger-txt: var(--text-color-6);
      --danger-border: #df172b;

      /* Notification Colors */
      --down-red: #b84155;
      --down-red-rgb: 184, 65, 85;
      --down-red-text-color: #b84155;

      --up-green: #39886b;
      --up-green-rgb: 57, 136, 107;
      --up-green-text-color: #39886b;

      --medium-yellow: #a8802c;
      --medium-yellow-rgb: 168, 128, 44;
      --medium-yellow-text-color: #a8802c;

      --default-gray: #757575;
      --default-gray-rgb: rgb(117, 117, 117);
      --default-gray-text-color: #757575;

      /*Disable colors start*/
      --disable-bg-color-1: #f7f7f7;
      --disable-bg-color-2: rgb(28, 27, 31, 0.12);
      --disable-bg-color-3: rgb(28, 27, 31);
      --disable-bg-color-4: rgb(28, 27, 31, 0.5);

      --disable--text-color-6: rgb(28, 27, 31, 0.38);

      --disable--border-color-1: rgb(28, 27, 31, 0.12);
      --disable--border-color-2: #dee2e6;
      /*Disable colors end*/
      
      /* table */
      --table-header-text: var(--text-color-4);
      --table-header-bg: #424242;

      /* Table colors start - AG Grid */
      --table-header-bg-color: var(--background-color-5);
      --table-header-txt-color: #b9b8b8;
      --table-bg-color-1: #2d2d2d;
      --table-bg-color-2: var(--background-color-11);
      --table-bg-color-3: rgba(51, 51, 51, 0.5);
      --table-odd-row-background-color: #3838383b;
      --table-txt-color: #eeeeee;      /*txt-color-body-shaded-4*/
      --table-disabled-txt-color: #4c4c4c;
      --table-row-hover-color: rgba(98, 98, 98, 0.06);
      --table-border-color-1: #2d2d2d;
      --table-border-color-2: #434343;
      --table-card-shadow: 0 1px 4px 1px rgb(86 86 86 / 40%);
      /* Table colors end */
    }
}
