@import "variables";
@import "color-palette";

/*Defining existing themes*/
$theme: LIGHT, DARK;

///////////////////////////////
// Background color Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $bg_color_key, $bg_color in map-get($color_palette, "background") {
    .#{$theme_key} .bg-color-#{$bg_color_key} {
      background-color: $bg_color !important;
    }
  }
}

///////////////////////////////
// Text Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $color_key, $text_color in map-get($color_palette, "text") {
    .#{$theme_key} .txt-color-#{$color_key} {
      color: $text_color !important;
    }
  }
}

///////////////////////////////
// Border Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $color_key, $color in map-get($color_palette, "border") {
    @each $side_key, $side_values in $sides {
      @each $width_key, $width_value in $border_width_values {
        $border_class: if(
          $side_key == "",
          ".b-#{$width_key}-#{$color_key}",
          ".b-#{$side_key}-#{$width_key}-#{$color_key}"
        );

        .#{$theme_key} #{$border_class} {
          @each $side-value in $side_values {
            border-#{$side-value}: $width_value solid #{$color} !important;
          }
        }
      }
    }
  }
}

///////////////////////////////
// Core Button Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $button_key, $button_colors in map-get($color_palette, "button") {
    .#{$theme_key} .btn-#{$button_key} {
      background-color: map-get($button_colors, "bg") !important;
      color: map-get($button_colors, "contrast-text") !important;
      border: 1px solid  map-get($button_colors, "border") !important;
    }
    .#{$theme_key} .btn-#{$button_key}:hover {
      background-color: map-get($button_colors, "hover") !important;
    }
    .#{$theme_key} .btn-#{$button_key}:active {
      background-color: map-get($button_colors, "click") !important;
    }
    .#{$theme_key} .btn-#{$button_key}:disabled {
      background-color: var(--btn-bg-disabled) !important;
      color: var(--btn-text-disabled) !important;
      border: 1px solid var(--btn-border-disabled) !important;
    }
  }
}

///////////////////////////////
// Status Button Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $button_key, $button_colors in map-get($color_palette, "button") {
    .#{$theme_key} .status-btn-#{$button_key} {
      background-color: map-get($button_colors, "bg") !important;
      color: map-get($button_colors, "contrast-text") !important;
      border: 1px solid map-get($button_colors, "border") !important;
    }
    .#{$theme_key} .status-btn-#{$button_key}:hover {
      background-color: map-get($button_colors, "hover") !important;
    }
    .#{$theme_key} .status-btn-#{$button_key}:active {
      background-color: map-get($button_colors, "click") !important;
    }
    .#{$theme_key} .status-btn-#{$button_key}:disabled {
      background-color: var(--btn-bg-disabled) !important;
      color: var(--btn-text-disabled) !important;
      border: 1px solid var(--btn-border-disabled) !important;
    }
  }
}

///////////////////////////////
// Icon Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $icon_key, $icon_colors in map-get($color_palette, "button") {
    .#{$theme_key} .icon-#{$icon_key} {
      color: map-get($icon_colors, "bg") !important;
      fill: map-get($icon_colors, "bg") !important;
    }
  }
}

///////////////////////////////
// Status Chip Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $bg_color_key, $bg_color in map-get($color_palette, "status") {
    .#{$theme_key} .status-#{$bg_color_key} {
      background-color: map-get($bg_color, "bg") !important;
      color: map-get($bg_color, "text") !important;
      border: 1px solid map-get($bg_color, "border") !important;
    }
  }
}

///////////////////////////////
// Risk Rating Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $bg_color_key, $bg_color in map-get($color_palette, "status") {
    .#{$theme_key} .risk-rating-#{$bg_color_key} {
      background-color: map-get($bg_color, "border") !important;
    }
  }
}

///////////////////////////////
// File-Uploader Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  @each $bg_color_key, $uploader_color in map-get($color_palette, "uploader") {
    .#{$theme_key} .file-#{$bg_color_key} {
      background-color: map-get($uploader_color, "bg") !important;
      border: 2px dashed map-get($uploader_color, "border") !important;
    } 
  }
}

///////////////////////////////
// Sidebar Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  .#{$theme_key} .sidebar-link-class.active {
    font-weight: map-get($font_weights, "extra-bold") !important;
    background-color: map-get(
      map-get($color_palette, "sidebar"),
      "link-active"
    ) !important;
  }

  .#{$theme_key} .sidebar-link-class:hover {
    background-color: map-get(
      map-get($color_palette, "sidebar"),
      "link-hover"
    ) !important;
  }
}

///////////////////////////////
// Table Theme
///////////////////////////////

@each $theme_key, $theme_colors in $theme {
  .#{$theme_key} .table-header-row {
    color: map-get(map-get($color_palette, "table"), "header-text") !important;
    background-color: map-get(
      map-get($color_palette, "table"),
      "header-bg"
    ) !important;
  }
}

///////////////////////////////
// Scrollbar Theme
///////////////////////////////

// @each $theme_key, $theme_colors in $theme {
//   .#{$theme_key} ::-webkit-scrollbar {
//     width: $scrollbar-width;
//   }

//   .#{$theme_key} ::-webkit-scrollbar-track {
//     background: map-get(
//       map-get($color_palette, "scrollbar"),
//       "track"
//     ) !important;
//   }

//   .#{$theme_key} ::-webkit-scrollbar-thumb {
//     background: map-get(
//       map-get($color_palette, "scrollbar"),
//       "thumb"
//     ) !important;
//   }

//   .#{$theme_key} ::-webkit-scrollbar-thumb:hover {
//     background: map-get(
//       map-get($color_palette, "scrollbar"),
//       "thumb-hover"
//     ) !important;
//   }
// }

///////////////////////////////
// Shadow Theme
///////////////////////////////

// text shadow

@each $theme_key, $theme_colors in $theme {
  @each $color_key, $shadow_color in map-get($color_palette, "shadow") {
    .#{theme_key} .txt-shadow-#{$color_key} {
      text-shadow: 2px 2px 2px $shadow_color !important;
    }
  }
}

// box shadow

@each $theme_key, $theme_colors in $theme {
  @each $color_key, $shadow_color in map-get($color_palette, "shadow") {
    .#{theme_key} .box-shadow-#{$color_key} {
      box-shadow: 2px 2px 2px $shadow_color !important;
    }
  }
}

// shadow background

@each $theme_key, $theme_colors in $theme {
  @each $color_key, $shadow_color in map-get($color_palette, "shadow") {
    .#{theme_key} .bg-shadow-#{$color_key} {
      background-color: $shadow_color !important;
    }
  }
}