///////////////////////////////
//Sides variables
///////////////////////////////

$sides: (
        "": ("top" "bottom" "left" "right"),
        "t": ("top"),
        "b": ("bottom"),
        "l": ("left"),
        "r": ("right"),
        "lr": ("left" "right"),
        "tb": ("top" "bottom")
);

///////////////////////////////
//Spacing variables
///////////////////////////////

$spacing: (
        "none": 0px,
       "2xs": 2px,
        "xs": 4px,
        "sm": 8px,
        "md": 16px,
        "lg": 24px,
        "xl": 39px
);

///////////////////////////////
//Font variables
///////////////////////////////

//font weights

$font_weights: (
        "extra-light": 200,
        "light": 300,
        "normal": 400,
        "medium": 500,
        "bold": 600,
        "extra-bold": 700,
);

//font sizes

$font_sizes: (
        "xs": 1.1rem,
        "sm": 1.3rem,
        "md": 1.6rem,
        "lg": 2.2rem,
        "xl": 2.6rem,
        "2xl":3rem,
        "3xl": 4rem,
);

// font_decorations

$font_decorations:(
        "ol":overline,
        "lt":line-through,
        "ul":underline,
        "ulol":underline overline,
        "none":none,
);

// font-transform

$font_transform:(
        "cp":capitalize,
        "up":uppercase,
        "lw":lowercase,
        "fw":full-width,
        "none":none,
);

// list-style

$list_type:(
        "cr":circle,
        "sq":square,
        "ur":upper-roman,
        "la":lower-alpha,
        "none":none,
);
/////
///////////////////////////////
//Border variables
///////////////////////////////

$border_radius_values: (
        "xs": 5px,
        "sm": 10px,
        "md": 30px,
        "lg": 100px,
        "xl": 150px
);

$border_width_values: (
        "none": 0,
        "xs": 0.5px,
        "sm": 1px,
        "md": 2px,
        "lg": 3px,
        "xl": 4px
);

///////////////////////////////
//Gap variables
///////////////////////////////

$spacer: 1rem;
$gap_values: (
  "zero": 0,
  "xs": $spacer * .25,
  "sm": $spacer * .5,
  "md": $spacer,
  "lg": $spacer * 1.5,
  "xl": $spacer * 3,
);

///////////////////////////////
// Component specific colors
///////////////////////////////

// Scrollbar
$scrollbar-width: 4px;

///////////////////////////////
// Alignment variables
///////////////////////////////

//widths and heights

$display_sizes: (
  "auto" : auto,
  "content" : fit-content,
//   "full": 100%,
//   "half": 50%,
//   "quarter": 25%,
//   "one-third": 33.33%,
//   "two-third": 66.66%,
);
