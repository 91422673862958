:root {
    .LIGHT {
        --client-primary: #04bdea;
        --client-secondary: #ffffff;

        --loader-spinner-color: var(--client-primary);
        --toggle-bar-bg-color: rgba(37, 169, 223, .5);
        /* --highlight-primary: rgba(37, 169, 223, .5); */

        /* background */
        --background-color-primary: var(--client-primary);
        --background-color-secondary: var(--client-secondary);
        --bg-color-highlight-primary: var(--highlight-primary);
        --background-color-1: #ffffff;
        --background-color-2: #25a9df; 
        --background-color-3: #EAEAEA; 
        --background-color-4: #ffffff; 
        --background-color-5: #568091;
        --background-color-6: #c5d8ed;
        --background-color-7: #f8f9f9;
        --background-color-8: #ffffff;
    
       

        /*  border */
        --border-color-primary: var(--client-primary);
        --border-color-secondary: var(--client-secondary);
        --border-color-1:  #dee2e6; 
        --border-color-2: #d3d3d3;
        --border-color-3: #08344c; 
        --border-color-4: #df1111;
        --border-color-5: #25a9df;


       
        /* text */
        --text-color-primary: var(--client-primary);
      --text-color-secondary: var(--client-secondary);
      --text-color-highlight-primary: var(--highlight-primary);
      --text-color-default: #000000;
        --text-color-1: #ffffff;
        --text-color-2: #04bdea; 
        --text-color-3: #0000EE;
        --text-color-4: #000000;
        --text-color-5: #76923c;
        --text-color-6 :#04bdea; 
       
      

        /* primary button */
        --btn-bg-primary: var(--client-primary);
        --btn-text-primary: var(--text-color-1);
        --btn-border-primary: var(--border-color-primary);
        --btn-bg-primary-active: color-mix(in srgb, var(--client-primary) 60%, white);
        --btn-bg-primary-hover: color-mix(in srgb,var(--client-primary) 80%, rgb(255, 255, 255));

        /* secondary button */
        --btn-bg-secondary: var(--background-color-1);
        --btn-text-secondary: var(--text-color-primary);
        --btn-border-secondary: var(--border-color-primary);
        --btn-bg-secondary-active: color-mix(in srgb, var(--client-secondary) 80%, black);
        --btn-bg-secondary-hover: color-mix(in srgb, var(--client-primary) 10%, rgb(255, 255, 255));

        /* tertiary button */
        --btn-bg-tertiary: var(--background-color-1);
        --btn-text-tertiary: var(--text-color-primary);
        --btn-border-tertiary: var(--border-color-secondary);
        --btn-bg-tertiary-active: color-mix(in srgb, var(--client-secondary) 60%, white);
        --btn-bg-tertiary-hover: color-mix(in srgb, var(--client-secondary) 80%, black);

        /* disabled button */
        --btn-bg-disabled: var(--background-color-5);
        --btn-text-disabled: var(--text-color-6);
        --btn-border-disabled: var(--border-color-3);

        /* sidebar
        --sidebar-link-hover: var(--background-color-3);
        --sidebar-link-active: var(--background-color-4); */

        /* table
        --table-header-text: var(--text-color-2);
        --table-header-bg: #deeff9;   #d9e4ff */

        /* scrollbar */
        /* --scrollbar-track: green;
        --scrollbar-thumb: red;
        --scrollbar-thumb-hover: blue;  */

        /* shadow */
        --shadow-dark: rgba(0, 0, 0, 0.32);
        --shadow-light: rgba(54, 124, 185, 0.33);
        --shadow-success: #2ec0711a;
        --shadow-danger: #f6bebe4d;
        --shadow-warning: #dacc304f; 
        --shadow-gray: #b6b5b552;

        --text-danger:#ff3e3e;;
        --text-info: #0d6efd;
        --text-success: #76923c;
        --text-warning: #ff832b;
        --text-caution: #998C00;

        --basic: #F1F1F1;
        --default: #FAFAFA;
        --primary: #165D83;
        --success: #2EC071;
        --info: #32BBF3;
        --warning: #FFC107;
        --danger: #DC3545;
        --gray: #A7A7A7;

        --down-red:#d00101;
        --down-red-rgb:208, 1, 1;
        --down-red-text-color:#d00101;
    
        --up-green:#15963e;
        --up-green-rgb:21, 150, 62;
        --up-green-text-color:#15963e;

        --medium-yellow:#baba15;
        --medium-yellow-rgb:186, 186, 21;
        --medium-yellow-text-color:#baba15;

        /*Disable colors start*/
        --disable-bg-color-1: #F7F7F7;
        --disable-bg-color-2: rgb(28, 27, 31, .12);
        --disable-bg-color-3: rgb(28, 27, 31);
        --disable-bg-color-4: rgb(28, 27, 31, .5);

        --disable--text-color-1: rgb(28, 27, 31, .38);

        --disable--border-color-1: rgb(28, 27, 31, .12);
        --disable--border-color-2: #DEE2E6;
        /*Disable colors end*/
    }
}
