.LIGHT{
    .MuiRadio-root {
    color: #bebebf !important;
    }
    .MuiRadio-root.Mui-checked {
    color: #2596be !important;
    }
}
.DARK{
    .MuiRadio-root {
    color:#ffffff !important;
    }
    .MuiRadio-root.Mui-checked {
    color: #2596be !important;
    }
}