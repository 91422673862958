//////////////////////////////
/// Position classes
//////////////////////////////

$position-property-values: absolute, relative, sticky, fixed, static, inherit, revert, initial, unset;

@each $position_value in $position-property-values {
    .p-#{$position_value} {
        position: $position_value !important;
    }
}

//////////////////////////////
/// Flexbox classes
//////////////////////////////

.display-flex {
    display: flex !important;
}

$flex-direction-values: row, row-reverse, column, column-reverse;

@each $direction-value in $flex-direction-values {
    .f-#{$direction-value} {
        flex-direction: $direction-value !important;
    }
}

$flex-wrap-values: wrap, nowrap;

@each $wrap-value in $flex-wrap-values {
    .f-#{$wrap-value} {
        flex-wrap: $wrap-value !important;
    }
}

//////////////////////////////
/// Alignment classes
//////////////////////////////

$alignment-properties: (

  //justify propety
  "jc": justify-content,
  "ji": justify-items,
  "js": justify-self,

  //align propety
  "ac": align-content,
  "ai": align-items,
  "as": align-self,
);

$alignment-property-values: (
    
    // Positional alignment
    "center": center,
    "start" : flex-start,
    "end"   : flex-end,
    "baseline" : baseline,

    // Distributed alignment
    "between" : space-between,
    "around" : space-around,
    "evenly" : space-evenly,
    "stretch": stretch,

    // Global Values
    "normal" : normal,
    "unset" : unset,
    "initial" : initial,
    "inherit" : inherit,
    "revert" : revert,
);

@each $property_key, $property in $alignment-properties {
    @each $property_value_key, $property_value in $alignment-property-values {
        .#{$property_key}-#{$property_value_key} {
            #{$property}: $property_value !important;
        }
    }
}

//////////////////////////////
/// Overflow Classes
//////////////////////////////


$overflow-properties: auto, visible, scroll, hidden, clip, unset, revert, initial, inherit;
$overflow-side: y, x;

@each $overflow-value in $overflow-properties {
    .over-#{$overflow-value} {
        overflow: $overflow-value !important;
    }
    @each $side in $overflow-side {
        .over-#{$side}-#{$overflow-value} {
            overflow-#{$side}: $overflow-value !important;
        }
    }
}


//////////////////////////////
/// Cursor Classes
//////////////////////////////

$cursor-properties: (
    "cursor-default" : default,
    "pointer" : pointer,
    "cursor-text" : text,
    "cursor-move" : move,
    "not-allowed" : not-allowed,
);


@each $cursor_key, $cursor_value in $cursor-properties {
    .#{$cursor_key} {
        cursor: $cursor_value !important;
    }
}

//////////////////////////////
/// Pointer-Events Classes
//////////////////////////////

$pointer-events-properties: none, auto;

@each $pointer_event in $pointer-events-properties {
    .pointer-#{$pointer_event} {
        pointer-events: $pointer_event !important;
    }
}

//////////////////////////////
/// Visibility Classes
//////////////////////////////

$visibility-property-values: collapse, hidden, visible, inherit, initial, revert, unset;

@each $visibility-value in $visibility-property-values {
    .visibility-#{$visibility-value} {
        visibility: $visibility-value !important;
    }
}
//////////////////////////////
/// Text Alignment classes
//////////////////////////////

$txt-alignment-properties: (
   "txt-align" : text-align,
);

$txt-alignment-property-values: (
    "center" : center,
    "left" : left,
    "right" : right,
    "justify" : justify,
);

@each $property_key, $property in $txt-alignment-properties {
    @each $property_value_key, $property_value in $txt-alignment-property-values {
        .#{$property_key}-#{$property_value_key} {
            #{$property}: $property_value !important;
        }
    }
}